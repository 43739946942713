.navbar {
  z-index: 100;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  font-family: "Space Mono", monospace;
  position: fixed;
}

.logo {
  color: white;
  padding: 1rem;
  margin-left: 1.5rem;
}

.logo-anchor {
  text-decoration: none;
  padding-left: 0;
}

.full-menu {
  display: flex;
  visibility: hidden;
  max-width: 0;
}

.full-menu li a:hover {
  font-style: italic;
}

/* Large breakpoint */
@media screen and (min-width: 992px) {
  .full-menu {
    visibility: visible;
    max-width: none;
  }
  .full-menu li {
    list-style: none;
    margin: 0 2rem;
  }
}