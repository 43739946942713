.footer {
  display: flex;
  justify-content: right;
  margin: 4rem auto 0;
  padding: .75rem 0;
}

.footer p {
  margin: 0 2rem;
  font-size: small;
}