.hero {
  font-family: 'Space Mono', monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
}

.hero-text-wrapper {
  padding: 0;
  margin: 1rem;
}

.hero-pre-text {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}

.hero-title {
  font-size: 2.875rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
}

.hero-post-text {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 0.5rem 0;
}

.hero-welcome {
  margin-top: 0;
}

.portrait {
  width: 100%;
  max-width: 300px;
  padding: 1rem;
  margin: 1rem;
}

.hero-button {
  background-color: transparent;
  border: 2px solid white;
}

.hero-button:hover {
  background-color: white;
  color: black;
}

.hero-button:active {
  background-color: black;
  color: white;
}

@media (min-width: 910px) {
  .hero {
    flex-direction: row-reverse;
    padding-top: 5rem;
  }
  .hero-text-wrapper {
    padding: 0 0 0 3.5rem;
    max-width: 500px;
    margin: 0;
  }
  .portrait {
    display: flex;
    border-radius: 100%;
    width: 100%;
  }
}