.project-img-top {
  width: 100%;
  object-fit: cover;
}

.project-body {
  height: 110px;
  padding: 0 1rem 1rem 1rem;
}

.project-title {
  height: 20px;
  font-size: large;
  margin-top: 0.5rem;
}

.project-overlay {
  border-radius: 0 0 30px 30px;
}

.overlay-text {
  padding: 0.5rem 1rem 1rem 1rem;
  font-size: 1.125rem;
}

.project-overlay-title {
  margin: 0.5rem 0;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 0.5rem;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.project-button {
  background-color: rgba(76, 76, 77, 0.986);
  border: none;
}

.project-button:hover {
  background-color: rgba(56, 56, 57, 0.986);
}

.project-button:active {
  background-color: rgba(36, 36, 37, 0.986);
}
