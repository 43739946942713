html {
  scroll-behavior: smooth !important;
}

body {
  color: white;
  background: linear-gradient(90deg, rgba(12,18, 12,1) 0%, rgba(95,75,75,1) 100%);
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

a {
  color: white;
  text-decoration: none;
}

/* section headings */
h6 {
  font-family: 'Space Mono', monospace;
  font-size: 2rem;
  font-weight: 700;
  margin: 2rem 3.5rem;
}

/* flex class used in ProjectGallery and SkilLGallery components*/
.flex-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.card-wrapper {
  max-width: 375px;
  min-width: 275px;
  margin: 10px;
}
.card {
  min-width: 100px;
  min-height: 100px;
  border-radius: 30px;
  background-color: rgba(76, 76, 77, 0.986);
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.card:hover .overlay {
  opacity: 1;
}

@media (min-width: 768px) {
  main {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media (min-width: 1190px) {
  .flex-display {
    justify-content: left;
  }
}