.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  border-radius: 30px;
}

.skill-image {
 width: 80%; 
}

.skill-overlay {
  border-radius: 30px;
}

.skill-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.skill-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
}